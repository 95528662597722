import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { Container } from 'components/container';
import { HeaderSecond } from 'components/header-second';
import { Paragraph } from 'components/paragraph';
import { SectionTiles } from 'components/section-tiles';
import { useTranslatedTitle } from 'hooks/use-translated-title';
import {
    arrayOf,
    bool,
    node,
    object,
    oneOfType,
    shape,
    string,
} from 'prop-types';

const SContainer = styled(Container)`
    content-visibility: auto;
    contain-intrinsic-size: 1000px;
`;

const STextWrapper = styled.div`
    text-align: center;
    margin: 0 auto 3.75rem;
    ${({ textWrapperCss }) => textWrapperCss}
`;

const SHeaderSecond = styled(HeaderSecond)`
    ${({ titleCss }) => titleCss}
`;

const SParagraph = styled(Paragraph)`
    margin-top: 1.5rem;
`;

export const SectionTilesSection = ({
    title,
    paragraph,
    data,
    textWrapperCss,
    titleCss,
    sectionTilesCss,
    sectionTileCss,
    isParagraphFormattedPreviously,
    id,
}) => {
    const { kebabCaseId, handleCopyUrl } = useTranslatedTitle(title);

    return (
        <SContainer id={id}>
            <STextWrapper textWrapperCss={textWrapperCss}>
                {title && (
                    <SHeaderSecond
                        titleCss={titleCss}
                        id={kebabCaseId}
                        handleCopyUrl={handleCopyUrl}
                    >
                        <FormattedMessage id={title} />
                    </SHeaderSecond>
                )}
                {paragraph && (
                    <SParagraph>
                        {isParagraphFormattedPreviously ? (
                            paragraph
                        ) : (
                            <FormattedMessage id={paragraph} />
                        )}
                    </SParagraph>
                )}
            </STextWrapper>
            <SectionTiles
                tilesData={data}
                sectionTilesCss={sectionTilesCss}
                sectionTileCss={sectionTileCss}
            />
        </SContainer>
    );
};

SectionTilesSection.propTypes = {
    data: arrayOf(
        shape({
            icon: oneOfType([node.isRequired, object.isRequired]),
            text: string,
            description: string,
        }),
    ).isRequired,
    title: string,
    paragraph: string,
    textWrapperCss: arrayOf(string),
    titleCss: arrayOf(string),
    sectionTilesCss: arrayOf(string),
    isParagraphFormattedPreviously: bool,
    id: string,
};

SectionTilesSection.defaultProps = {
    data: [],
    title: '',
    paragraph: '',
    textWrapperCss: [''],
    sectionTilesCss: [''],
    isParagraphFormattedPreviously: false,
    id: undefined,
};
