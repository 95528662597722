import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { CONSTANTS } from 'constants/styles/constants';
import { arrayOf, node, object, shape, string } from 'prop-types';

const SContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(${({ tilesLength }) => tilesLength}, 1fr);
    justify-items: center;
    gap: 2rem 1.5rem;

    ${CONSTANTS.MEDIA.max1024`
        grid-template-columns: repeat(2, 1fr);
    `}

    ${CONSTANTS.MEDIA.max660`
        grid-template-columns: 1fr;
        gap: 5rem 1.5rem;
    `}

    ${({ sectionTilesCss }) => sectionTilesCss};
`;

const STile = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 18rem;
    ${({ sectionTileCss }) => sectionTileCss};

    ${CONSTANTS.MEDIA.max500`
        max-width: 20rem;
    `}
`;

const SIconWrapper = styled.div`
    padding: 1.53rem;
    border-radius: 50%;
    display: flex;
    background-color: var(--gray-color-90);
`;

const STitle = styled.p`
    font-size: 1.375rem;
    margin-top: 1.75rem;
    font-weight: 700;
    text-align: center;
    white-space: pre-wrap;
`;

const SDescription = styled.p`
    margin-top: 1.75rem;
    text-align: center;
    white-space: pre-wrap;
`;

export const SectionTiles = ({
    tilesData,
    className,
    sectionTilesCss,
    sectionTileCss,
}) => {
    const tilesLength = tilesData.length;
    const sectionTilesToRender = tilesData.map(
        ({ icon, text, description, descriptionValues }, index) => (
            <STile key={index} className="tile" sectionTileCss={sectionTileCss}>
                <SIconWrapper>{icon}</SIconWrapper>
                <STitle>
                    <FormattedMessage id={text} />
                </STitle>
                {description ? (
                    <SDescription className="benefit-description">
                        <FormattedMessage
                            id={description}
                            values={descriptionValues}
                        />
                    </SDescription>
                ) : null}
            </STile>
        ),
    );
    return (
        <SContainer
            className={className}
            tilesLength={tilesLength}
            sectionTilesCss={sectionTilesCss}
        >
            {sectionTilesToRender}
        </SContainer>
    );
};

SectionTiles.propTypes = {
    tilesData: arrayOf(
        shape({
            icon: node.isRequired,
            text: string.isRequired,
            description: string,
            descriptionValues: object,
        }),
    ).isRequired,
    className: string,
    sectionTilesCss: arrayOf(string),
    sectionTileCss: arrayOf(string),
};

SectionTiles.defaultProps = {
    className: '',
    sectionTilesCss: [''],
    sectionTileCss: [''],
};
