import React from 'react';
import { AWSView } from 'views/aws';
import { SEO } from 'components/seo';
import { TAGS } from 'constants/tags';
import caseStudiesMessages from 'constants/translations/case-studies/index.json';
import messages from 'constants/translations/technologies/aws.json';
import { graphql } from 'gatsby';
import Layout from 'layouts/standard';
import { object } from 'prop-types';
import { formatFaqData } from 'utilities/contentful';

const AWSPage = ({ location, data }) => {
    const faqData = formatFaqData(data?.allContentfulFaq.nodes[0]);

    return (
        <Layout
            location={location}
            messages={{ ...messages, ...caseStudiesMessages }}
        >
            <AWSView {...{ faqData }} />
        </Layout>
    );
};

AWSPage.propTypes = {
    location: object.isRequired,
};

export default AWSPage;
export const Head = () => <SEO tags={TAGS.AWS} />;

export const pageQuery = graphql`
    query AwsPageQuery {
        allContentfulFaq(filter: { slug: { eq: "technologies/aws" } }) {
            nodes {
                ...FaqFragment
            }
        }
    }
`;
