import React from 'react';
import { css } from 'styled-components';
import { SectionTilesSection } from 'modules/section-tiles-section';
import { string } from 'prop-types';
import EmptyWalletIcon from 'svgs/icons/ic-empty-wallet-tick.svg';
import MaximizeIcon from 'svgs/icons/ic-maximize.svg';
import ShieldSecurityIcon from 'svgs/technologies/aws/perks/shield-security.svg';
import ShieldIcon from 'svgs/technologies/aws/perks/shield-tick.svg';

const iconProps = {
    width: 42,
    height: 42,
};

const PERKS = [
    {
        icon: <MaximizeIcon {...iconProps} />,
        text: 'aws.perks.scalability.title',
        description: 'aws.perks.scalability.description',
    },
    {
        icon: <ShieldIcon {...iconProps} />,
        text: 'aws.perks.reliability.title',
        description: 'aws.perks.reliability.description',
    },
    {
        icon: <ShieldSecurityIcon {...iconProps} />,
        text: 'aws.perks.security.title',
        description: 'aws.perks.security.description',
    },
    {
        icon: <EmptyWalletIcon {...iconProps} />,
        text: 'aws.perks.costOptimization.title',
        description: 'aws.perks.costOptimization.description',
    },
];

export const AppDevelopmentPerksSection = ({ id }) => {
    return (
        <SectionTilesSection
            id={id}
            title="aws.topics.whyChoose"
            data={PERKS}
            titleCss={css`
                font-size: 3rem;
            `}
        />
    );
};

AppDevelopmentPerksSection.propTypes = {
    id: string.isRequired,
};
