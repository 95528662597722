import React from 'react';
import { CASE_STUDIES_TAG } from 'views/case-studies/constants';
import { BLOG_POST_TAGS } from 'constants/blog-post-tags';
import { PATHS } from 'constants/paths';
import { graphql, useStaticQuery } from 'gatsby';
import JavascriptSvg from 'svgs/blog/javascript.svg';
import EmptyWalletIcon from 'svgs/icons/ic-empty-wallet-tick.svg';
import MaximizeIcon from 'svgs/icons/ic-maximize.svg';
import TimerIcon from 'svgs/icons/ic-timer-start.svg';
import GlobalSearchIcon from 'svgs/technologies/aws/perks/global-search.svg';
import LampIcon from 'svgs/technologies/aws/perks/lamp-on.svg';
import ShieldSecurityIcon from 'svgs/technologies/aws/perks/shield-security.svg';
import ShieldIcon from 'svgs/technologies/aws/perks/shield-tick.svg';
import StatusIcon from 'svgs/technologies/aws/perks/status-up.svg';
import CertifiedIcon from 'svgs/technologies/work-with-mr/award.svg';
import MarketIcon from 'svgs/technologies/work-with-mr/calendar-tick.svg';
import MaintainersIcon from 'svgs/technologies/work-with-mr/code.svg';
import ClientsIcon from 'svgs/technologies/work-with-mr/people.svg';

const iconProps = {
    width: 42,
    height: 42,
};

export const BUSINESS_BENEFITS = [
    {
        title: 'aws.businessBenefits.benefits.scalability.title',
        description: 'aws.businessBenefits.benefits.scalability.description',
        icon: <MaximizeIcon {...iconProps} />,
    },
    {
        title: 'aws.businessBenefits.benefits.costOptimization.title',
        description:
            'aws.businessBenefits.benefits.costOptimization.description',
        icon: <EmptyWalletIcon {...iconProps} />,
    },
    {
        title: 'aws.businessBenefits.benefits.reliability.title',
        description: 'aws.businessBenefits.benefits.reliability.description',
        icon: <ShieldIcon {...iconProps} />,
    },
    {
        title: 'aws.businessBenefits.benefits.security.title',
        description: 'aws.businessBenefits.benefits.security.description',
        icon: <ShieldSecurityIcon {...iconProps} />,
    },
    {
        title: 'aws.businessBenefits.benefits.globalReach.title',
        description: 'aws.businessBenefits.benefits.globalReach.description',
        icon: <GlobalSearchIcon {...iconProps} />,
    },
    {
        title: 'aws.businessBenefits.benefits.acceleratedTime.title',
        description:
            'aws.businessBenefits.benefits.acceleratedTime.description',
        icon: <TimerIcon {...iconProps} />,
    },
    {
        title: 'aws.businessBenefits.benefits.innovation.title',
        description: 'aws.businessBenefits.benefits.innovation.description',
        icon: <LampIcon {...iconProps} />,
    },
    {
        title: 'aws.businessBenefits.benefits.focusOnCore.title',
        description: 'aws.businessBenefits.benefits.focusOnCore.description',
        icon: <StatusIcon {...iconProps} />,
    },
];

export const SECTIONS_TRANSLATION = [
    'aws.topics.awsConsulting',
    'aws.topics.whyChoose',
    'aws.topics.appDevelopmentServices',
    'aws.topics.hireDevelopers',
    'aws.topics.businessBenefits',
    'aws.topics.whyWorkWithMobileReality',
];

export const SECTIONS_ID = [
    'aws-consulting',
    'aws-why-choose-for-app-development',
    'aws-app-development-services',
    'aws-hire-developers',
    'aws-business-benefits',
    'aws-why-work-with-mobile-reality',
];

export const SERVICES = [
    {
        index: 1,
        section: 'architectureDesign',
        paragraphsCount: 1,
    },
    {
        index: 2,
        section: 'serverlessApplication',
        paragraphsCount: 1,
    },
    {
        index: 3,
        section: 'cloudMigration',
        paragraphsCount: 1,
    },
    {
        index: 4,
        section: 'performanceOptimization',
        paragraphsCount: 1,
    },
    {
        index: 5,
        section: 'buildApp',
        paragraphsCount: 1,
    },
];

export const useCaseStudiesData = () => {
    const images = useStaticQuery(graphql`
        {
            awsCostOptimization: file(
                relativePath: { eq: "case-studies/aws-cost-optimization.png" }
            ) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        width: 392
                        formats: [WEBP]
                    )
                }
            }
        }
    `);

    return [
        {
            id: 'awsCostOptimization',
            title: 'case-studies-page.awsCostOptimization',
            field: 'case-studies-page.field.healthcare',
            tags: [
                CASE_STUDIES_TAG.WEB_DEVELOPMENT,
                CASE_STUDIES_TAG.CLOUD_APPLICATION_DEVELOPMENT,
            ],
            image: images.awsCostOptimization.childImageSharp.gatsbyImageData,
            to: `${PATHS.CASE_STUDIES}/${PATHS.AWS_COST_OPTIMIZATION}`,
        },
    ];
};

export const PERKS_LIST = [
    {
        title: 'aws.workWithMobileReality.onTheMarket',
        icon: <MarketIcon />,
    },
    {
        title: 'aws.workWithMobileReality.certifiedDevelopers',
        icon: <CertifiedIcon />,
    },
    {
        title: 'aws.workWithMobileReality.ownersAndMaintainers',
        icon: <MaintainersIcon />,
    },
    {
        title: 'aws.workWithMobileReality.experiencedInWorking',
        icon: <ClientsIcon />,
    },
];

export const LATEST_RELATED_ARTICLES_SETTINGS = {
    hideTitle: true,
    showAllArticles: true,
    tags: [BLOG_POST_TAGS.CLOUD_DEVOPS],
    button: {
        to: `${PATHS.BLOG}/${PATHS.JAVASCRIPT}`,
        label: 'aws.footer.button',
    },
    categoryBanner: {
        title: 'aws.footer.title',
        description: 'aws.footer.description',
        svg: JavascriptSvg,
    },
};
